<template>
  <div style="z-index:2001; position:absolute">
    <ConfirmationDialog @confirm="unregister" v-if="displayConfirmDeleteDialog" style="z-index:3000; position:absolute"
      :title="'Voulez-vous vraiment désinscrire ce stagiaire ?'" message="" :primaryActionLabel="'Désinscrire'"
      :secondaryActionLabel="'annuler'" @close="displayConfirmDeleteDialog = false" />
  </div>
  <div style="z-index: 2000;">
    <TraineeRegistrationStatus v-if="displayTraineeRegistrationStatus" :trainee="selectedTrainee"
      :statuses="relevantStatuses" @statusUpdated="traineeStatusUpdated" :type="training.type"
      :trainingStatus="training.status?.id" :startDate="training.start_date" :endDate="training.end_date"
      :statusSelected="selectedStatus" @close="displayTraineeRegistrationStatus = false" :key="componentKey"
      @unregister="unregisterDialog" />
  </div>
  <AlertMessage :type="notificationType" :title="notificationTitle" class="mt-12" :content="notificationMessage"
    v-if="showNotification" />
  <AccountFormPanel v-if="displayAccountForm" @close="hideAccountFormPanel = false" :key="componentKey"
    style="z-index: 2000;" />
  <div class="px-4 sm:px-6 lg:px-8 pt-12">
    <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
      <div class="ml-4 mt-4 flex items-center justify-between w-full">
        <h3 class="text-base font-semibold leading-6 text-gray-900">Stagiaires</h3>
        <div class="ml-auto">
          <button @click="exportExcel"
            class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            <CloudArrowDownIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Exporter les pupitres
          </button>
        </div>
      </div>
    </div>
    <div class="mt-6 flex">
      <Listbox as="div" v-model="selectedStatus" @update:model-value="filterStatus(selectedStatus)" class="grow">
        <ListboxLabel class="block text-sm font-medium leading-6 text-gray-900">Filtrer par statut</ListboxLabel>
        <div class="relative mt-2">
          <ListboxButton
            class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
            <span class="flex items-center" v-if="selectedStatus">
              <span :class="statusClasses[selectedStatus.name]"
                class="inline-block h-2 w-2 flex-shrink-0 rounded-full"></span>
              <span class="ml-3 block truncate" v-if="selectedStatus.name">{{ selectedStatus.name }}</span>
              <span v-else-if="!selectedStatus || !selectedStatus?.name">Tous</span>
            </span>
            <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>
          <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <ListboxOptions
              class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <ListboxOption v-for="status in relevantStatuses" :key="status" :value="status"
                v-slot="{ active, selected }">
                <li
                  :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
                  <div class="flex items-center">
                    <span :class="statusClasses[status.name]" class="inline-block h-2 w-2 flex-shrink-0 rounded-full"
                      aria-hidden="true"></span>
                    <span :class="[selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate']">{{ status.name
                    }}</span>
                  </div>
                  <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ListboxOption>

            </ListboxOptions>
          </transition>
        </div>
      </Listbox>
      <button class="btn-secondary flex-none h-10 mt-auto ml-3"
        @click.prevent="changePage(1); selectedStatus = [];">Voir
        tout</button>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                  Nom
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Syndicat
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Nom de l'entreprise
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Taille de l'entreprise
                </th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Statut
                </th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Détails</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-for="person in traineesList" :key="person.email">
                <td class="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                  <router-link :to="'/stagiaires/' + person.id" target="_blank">
                    <button class="text-left">
                      <div class="flex items-center">
                        <div class="h-11 w-11 flex-shrink-0" v-if="person.temporary_profile_picture_url">
                          <img class="h-11 w-11 rounded-full object-cover" :src="person.temporary_profile_picture_url"
                            alt="" />
                        </div>
                        <div v-else>
                          <UserCircleIcon class="h-11 w-11 text-gray-400" />
                        </div>
                        <div class="ml-4">
                          <div class="font-medium text-gray-900 hover:text-indigo-600">
                            {{ person.user?.name }}
                          </div>
                          <div class="mt-1 text-gray-500 hover:text-indigo-600">{{ person.user?.email }}</div>
                        </div>
                      </div>
                    </button>
                  </router-link>
                </td>
                <td class="px-3 py-5 text-sm text-gray-500">
                  <div class="text-gray-900">{{ person.current_union?.name }}</div>
                  <div class="mt-1 text-gray-500">
                    <div v-for="mandate in person.mandates" :key="mandate.pivot.id">
                      <div v-if="mandate.pivot.is_valid">{{
                        mandate.name }}</div>
                    </div>
                  </div>
                </td>
                <td class="px-3 py-5 text-sm text-gray-500">{{ person.company_name }}</td>
                <td class="px-3 py-5 text-sm text-gray-500">{{ person.company_size?.name }}</td>
                <td class="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                  <button v-if="person.pivot?.registration_status_id === 2"
                    @click.prevent="handleTraineeRegistration(person)"
                    class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">Validé</button>
                  <button v-if="person.pivot?.registration_status_id === 3"
                    @click.prevent="handleTraineeRegistration(person)"
                    class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">Refusé</button>
                  <button v-if="person.pivot?.registration_status_id === 1"
                    @click.prevent="handleTraineeRegistration(person)"
                    class="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700 cursor-pointer">
                    <svg class="h-1.5 w-1.5 fill-orange-500" viewBox="0 0 6 6" aria-hidden="true">
                      <circle cx="3" cy="3" r="3" />
                    </svg>Préinscription</button>
                  <button v-if="person.pivot?.registration_status_id === 4"
                    @click.prevent="handleTraineeRegistration(person)"
                    class="inline-flex items-center gap-x-1.5 rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 cursor-pointer">
                    <svg class="h-1.5 w-1.5 fill-blue-500" viewBox="0 0 6 6" aria-hidden="true">
                      <circle cx="3" cy="3" r="3" />
                    </svg>En attente</button>
                </td>
                <td><button class="text-sm text-indigo-600 hover:text-indigo-900 ml-2"
                    @click.prevent="handleTraineeRegistration(person)">
                    Voir
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref } from 'vue';
import Pagination from '@/components/lists/PaginationTables.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import TraineeRegistrationStatus from '@/components/registration/TraineeRegistrationStatus.vue';
import {
  Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import AccountFormPanel from '@/components/users/AccountFormPanel.vue';
import client from '@/api/client';
import { CloudArrowDownIcon, UserCircleIcon } from '@heroicons/vue/24/outline';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';

const props = defineProps({
  trainees: Object,
  training: [Object, Array],
  statusSelected: [Object, Array],
});
const emit = defineEmits(['statusUpdated']);
// Pagination data
const currentPage = ref(1);
const totalPages = ref(1);
const pageSize = ref(30);

const traineesList = ref();
const relevantStatuses = ref([]);
const showNotification = ref(false);
const notificationType = ref('');
const notificationTitle = ref('');
const notificationMessage = ref('');
let dayRemaining = 0;
const displayConfirmDeleteDialog = ref(false);

const statusClasses = {
  Préinscription: 'bg-blue-400',
  Validé: 'bg-green-400',
  Refusé: 'bg-red-400',
  'En attente': 'bg-yellow-400',
};
const profile = ref();
const displayAccountForm = ref(false);

const componentKey = ref(0);

const hideAccountFormPanel = () => {
  displayAccountForm.value = false;
  componentKey.value += 1;
};

const selectedStatus = ref([]);

const displayTraineeRegistrationStatus = ref(false);
const selectedTrainee = ref(null);

const handleTraineeRegistration = (person) => {
  selectedTrainee.value = person;
  displayTraineeRegistrationStatus.value = true;
};

const traineeStatusUpdated = (status) => {
  emit('statusUpdated', selectedStatus.value);
  displayTraineeRegistrationStatus.value = false;
};

const exportExcel = async () => {
  try {
    const response = await client.get(`/api/trainings/${props.training.id}/export-attendees`, {
      responseType: 'blob',
    });

    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `stagiaires_${props.training.id}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Erreur lors de l'exportation des stagiaires:", error);
  }
};

const filterStatus = (status) => {
  selectedStatus.value = status;
  traineesList.value = props.trainees.filter((trainee) => trainee.pivot?.registration_status_id === status.id);
  totalPages.value = 1;
};

const changePage = (page) => {
  traineesList.value = props.trainees.slice((page - 1) * pageSize.value, page * pageSize.value);
  traineesList.value = traineesList.value.filter(trainee => {
    if (profile.value.role.slug === 'responsable-pedagogique') {
      return trainee.pivot?.registration_status_id !== 1;
    }
    return true;
  });
  currentPage.value = page;
};
const fetchRegistrationStatuses = async () => {
  try {
    const response = await client.get('/api/registration-statuses');
    relevantStatuses.value = response.data;
    if (profile.value.role.slug === 'responsable-pedagogique') {
      const filteredStatuses = relevantStatuses.value.filter(status => status.id !== 1);
      relevantStatuses.value = filteredStatuses;
    }
  } catch (error) {
    console.error('Failed to fetch registration statuses:', error);
  }
};

const unregister = async () => {
  try {
    const response = await client.delete(`api/trainings/${props.training.id}/unregister/${selectedTrainee.value.id}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo,';
    notificationMessage.value = response.data.message;
    displayConfirmDeleteDialog.value = false;
    emit('statusUpdated');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de la désincription';
    if (error.response) {
      notificationMessage.value = error.response.data.error;
    }
  }
};

const unregisterDialog = (person) => {
  displayConfirmDeleteDialog.value = true;
  selectedTrainee.value = person;
};
onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  traineesList.value = props.trainees.slice(0, pageSize.value);
  traineesList.value = traineesList.value.filter(trainee => {
    if (profile.value.role.slug === 'responsable-pedagogique') {
      return trainee.pivot?.registration_status_id !== 1;
    }
    return true;
  });
  if (props.trainees.length > pageSize.value) {
    totalPages.value = Math.ceil(props.trainees.length / pageSize.value);
  }
  fetchRegistrationStatuses();
  dayRemaining = Math.ceil((new Date(props.training?.start_date) - new Date()) / (1000 * 60 * 60 * 24));

  if (dayRemaining > 0 && dayRemaining <= 15) {
    showNotification.value = true;
    notificationType.value = 'warning';
    notificationTitle.value = 'Attention, il est bientôt temps de clotûrer les inscriptions';
    notificationMessage.value = `Il reste ${dayRemaining} jours avant le début de la formation`;
  } else if (dayRemaining > 15) {
    showNotification.value = true;
    notificationType.value = 'info';
    notificationTitle.value = `Il reste ${dayRemaining} jours avant le début de la formation`;
    notificationMessage.value = 'Les inscriptions doivent être clotûrées 7 jours avant le début.';
  } else if (dayRemaining === 7) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Vous devez clotûrer les inscriptions aujourd\'hui';
    notificationMessage.value = `Il reste ${dayRemaining} jours avant le début de la formation`;
  } else if (dayRemaining > 0 && dayRemaining < 7) {
    showNotification.value = true;
    notificationType.value = 'error';
    notificationTitle.value = 'Attention, il est bientôt temps de clotûrer les inscriptions';
    notificationMessage.value = `Il reste ${dayRemaining} jours avant le début de la formation`;
  }
  if (props.statusSelected) {
    selectedStatus.value = props.statusSelected;
    filterStatus(selectedStatus.value);
  }
});

</script>
